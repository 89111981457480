import { inject, Injectable, NgZone } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { doc, DocumentReference, Firestore, setDoc } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { authState } from 'rxfire/auth';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UM6ControlService } from '../../services/um6-control.service';
import { AppUser } from '../entities/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private firestore = inject(Firestore);
  private auth = inject(Auth, { optional: true });
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private ngZone = inject(NgZone);

  public userData: User; // Save logged in user data
  public userCredentials: AppUser; //user data that gets updated each sign in
  readonly homeRoute = '/';

  private um6ControlService: UM6ControlService = inject(UM6ControlService);

  constructor();

  constructor() {
    //  this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    //this.userData = JSON.parse(localStorage.getItem('user') ?? '{}');

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */

    if (this.auth) {
      authState(this.auth).subscribe((user: User | null) => {
        console.log('>>> authState: user', user);
        if (user) {
          this.updateUserInStorage(user);
          console.log('>>> this.authService.isLoggedIn', user);
        }
        this.um6ControlService.setLoggedIn$(user !== null).subscribe();
      });
    }
  }

  getAuthenticated$(): Observable<boolean> {
    return authState(this.auth).pipe(map((user: User | null) => user !== null));
  }

  private updateUserInStorage(user: User): void {
    if (user) {
      this.userData = user;
      localStorage.setItem('user', JSON.stringify(this.userData));
    } else {
      localStorage.setItem('user', 'null');
    }
  }

  // Sign in with email/password
  signIn$(email: string, password: string): Observable<void> {
    return of(null).pipe(
      switchMap(() =>
        from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
          switchMap((userCredentials: UserCredential) => this.updateUserData$(userCredentials.user))
        )
      )
    );
  }

  checkEmailVerification$(): Observable<boolean> {
    return of(this.auth.currentUser.reload()).pipe(
      switchMap(() => {
        if (this.auth.currentUser.emailVerified) {
          return of(true);
        }
        return of(false);
      })
    );
  }

  signInAnonymously$(): Observable<User> {
    return of(null).pipe(
      switchMap(() =>
        from(signInAnonymously(this.auth)).pipe(
          switchMap((userCredentials) => this.updateUserData$(userCredentials.user)),
          map(() => this.userData),
          catchError((error) => {
            window.alert(error.message);
            throw error;
          })
        )
      )
    );
  }

  // Sign up with email/password
  signUp$(email: string, password: string): Observable<void> {
    return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap((result: UserCredential) => from(this.sendVerificationMail()).pipe(map(() => result))),
      switchMap((result) => {
        // Call the SendVerificaitonMail() function when new user sign
        // up and returns promise
        return this.updateUserData$(result.user);
      })
    );
  }

  // Send email verfificaiton when new user sign up
  async sendVerificationMail(): Promise<void> {
    return await sendEmailVerification(this.userData);
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Sign in with Google
  googleAuth$(): Observable<void | UserCredential> {
    const provider = new GoogleAuthProvider();
    return this.authLogin$(provider);
  }

  twitterAuth$(): Observable<void | UserCredential> {
    const provider = new TwitterAuthProvider();
    return this.authLogin$(provider);
  }

  facebookAuth$(): Observable<void | UserCredential> {
    const provider = new FacebookAuthProvider();
    return this.authLogin$(provider);
  }

  // Auth logic to run auth providers
  authLogin$(provider: any): Observable<void> {
    return of(null).pipe(
      switchMap(() => from(signInWithPopup(this.auth, provider))),
      switchMap((userCredentials: UserCredential) => this.updateUserData$(userCredentials.user)),
      tap((userCredentials) => {
        this.ngZone.run(() => {
          this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment ?? undefined });
        });
      }),
      catchError((error) => {
        window.alert(error);
        throw error;
      })
    );
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  updateUserData$(user: User): Observable<void> {
    const userRef: DocumentReference<any> = doc(this.firestore, 'users', user.uid);
    const userData: AppUser = {
      uid: user.uid,
      email: user.email ?? '',
      displayName: user.displayName ?? '',
      photoURL: user.photoURL ?? '',
      emailVerified: user.emailVerified,
    };
    this.userCredentials = userData;

    return of(null).pipe(
      switchMap(() =>
        from(
          setDoc(userRef, userData, {
            merge: true,
          })
        )
      ),
      map(() => undefined as void),
      catchError((err) => {
        console.error('>>> err', err);
        throw err;
      })
    );
  }

  // Sign out
  signOut$(): Observable<void> {
    return of(null).pipe(
      switchMap(() => signOut(this.auth)),
      switchMap(() => this.um6ControlService.setLoggedIn$(false)),
    );
  }

  getUserId(): string {
    return this.userData.uid;
  }

  mapAuthErrorCodeToMessage(errorCode: string): string {
    switch (errorCode) {
      case 'auth/too-many-requests':
        return 'Too many requests. Please try again later.';
      case 'auth/unauthorized-domain':
        return 'The authentication domain is not authorized.';
      case 'auth/user-disabled':
        return 'The user account has been disabled.';
      case 'auth/web-storage-unsupported':
        return 'Web storage is not supported by your browser.';
      case 'auth/invalid-email':
        return 'The email address is badly formatted.';
      case 'auth/user-not-found':
        return 'User not found.';
      case 'auth/wrong-password':
        return 'The password is incorrect or this account does not exist.';
      case 'auth/missing-password':
        return 'Password is missing.';
      case 'auth/weak-password':
        return 'The Passwords must be at least 6 characters long, contain both uppercase and lowercase letters, include at least one number, and have at least one special character (e.g., !, @, #, $, %).';
      case 'auth/network-request-failed':
        return 'No internet connection.';
      case 'auth/not-verified':
        return 'User is not verified.';
      case 'auth/email-already-in-use':
        return 'The email address is already in use by another account.';
      case 'auth/operation-not-allowed':
        return 'The operation is not allowed. Please contact support.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with the same email address but different sign-in credentials.';
      case 'auth/popup-closed-by-user':
        return 'The popup has been closed by the user before completing the sign-in process.';
      case 'auth/cancelled-popup-request':
        return 'A popup request was cancelled.';
      case 'auth/invalid-credential':
        return 'The provided credential is invalid.';
      case 'auth/credential-already-in-use':
        return 'The credential is already in use by another account.';
      case 'auth/requires-recent-login':
        return 'This operation is sensitive and requires recent authentication. Please log in again.';
      case 'auth/timeout':
        return 'The request has timed out. Please try again later.';
      default:
        return 'An error occurred during authentication.';
    }
  }

  // Zusammengefasste Bedeutung der Fehlerfälle:
  // auth/too-many-requests: Der Benutzer hat zu viele Anfragen gestellt, was die Server überlastet.
  // auth/unauthorized-domain: Die Domain, von der die Anfrage kommt, ist nicht autorisiert, sich mit Firebase zu verbinden.
  // auth/user-disabled: Das Benutzerkonto wurde deaktiviert, oft aufgrund eines Verstoßes gegen die Nutzungsbedingungen.
  // auth/web-storage-unsupported: Der Browser des Benutzers unterstützt keinen Web-Speicher.
  // auth/invalid-email: Die angegebene E-Mail-Adresse ist ungültig oder schlecht formatiert.
  // auth/user-not-found: Der Benutzer, der sich anmelden möchte, wurde nicht gefunden.
  // auth/wrong-password: Das Passwort ist falsch oder das Konto existiert nicht.
  // auth/missing-password: Kein Passwort wurde angegeben.
  // auth/weak-password: Das angegebene Passwort ist zu schwach und erfüllt nicht die Sicherheitsanforderungen.
  // auth/network-request-failed: Die Netzwerkverbindung ist fehlgeschlagen, oft aufgrund von fehlendem Internetzugang.
  // auth/not-verified: Der Benutzer hat seine E-Mail-Adresse noch nicht verifiziert.
  // auth/email-already-in-use: Die angegebene E-Mail-Adresse wird bereits für ein anderes Konto verwendet.
  // auth/operation-not-allowed: Die angeforderte Operation (z. B. Anmeldung) ist nicht erlaubt.
  // auth/account-exists-with-different-credential: Ein Konto mit derselben E-Mail-Adresse existiert bereits, aber mit einer anderen Authentifizierungsmethode.
  // auth/popup-closed-by-user: Der Benutzer hat das Anmelde-Popup vorzeitig geschlossen.
  // auth/cancelled-popup-request: Der Popup-Anmeldevorgang wurde abgebrochen.
  // auth/invalid-credential: Die angegebenen Anmeldeinformationen sind ungültig.
  // auth/credential-already-in-use: Die Anmeldeinformationen (z. B. Social Login) werden bereits von einem anderen Konto verwendet.
  // auth/requires-recent-login: Der Benutzer muss sich erneut anmelden, um eine sensible Aktion durchzuführen.
  // auth/timeout: Die Anfrage hat den Zeitlimit überschritten.

  // auth/weak-password Fehlermeldung tritt auf:
  //Ein sicheres Passwort, das keine Fehlermeldung wie auth/weak-password verursacht,
  // sollte mindestens 6 Zeichen lang sein und eine Mischung aus Großbuchstaben,
  //  Kleinbuchstaben, Zahlen und Sonderzeichen enthalten.
  // Wenn du diese Regeln befolgst,
  // wird das Passwort die Sicherheitsanforderungen von Firebase erfüllen und der Fehler tritt nicht mehr auf.
}
